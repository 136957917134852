import React from 'react'
import { Link, graphql } from 'gatsby'

import SEO from "../components/seo"
import GrowHero from "../components/grow-hero"
import Benefits from "../components/benefits"
import Footer from "../components/footer"
import "../components/master.css"


class PragIndex extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    return (
      <div>
        <GrowHero />
        <Footer />
      </div>
    )
  }
}

export default PragIndex
